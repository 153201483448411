<template>
  <div class="user-login register">
    <div class="left">
      <img :src="bannerUrl" alt="" />
    </div>
    <div class="right">
      <div class="title">{{ $t('zhu-ce') }}</div>
      <div class="flex btn-line">
        <el-button
          :class="tabIndex == 1 ? 'active' : ''"
          @click="changeTab(1)"
          >{{ $t('you-xiang-0') }}</el-button
        >
        <el-button
          :class="tabIndex == 2 ? 'active' : ''"
          @click="changeTab(2)"
          >{{ $t('shou-ji-hao') }}</el-button
        >
      </div>
      <div class="login-form">
        <el-form :model="form" ref="form" :rules="formRules">
          <template v-if="tabIndex == 1">
            <el-form-item :label="$t('you-xiang-0')" prop="email" required>
              <el-input
                v-model="form.email"
                :placeholder="$t('qing-shu-ru-you-xiang-1')"
              ></el-input>
            </el-form-item>

            <el-form-item :label="$t('yan-zheng-ma')" prop="code" required>
              <div class="verify-code-input">
                <el-input
                  v-model="form.code"
                  :placeholder="$t('qing-shu-ru-yan-zheng-ma')"
                ></el-input>
                <el-button
                  :disabled="!!countdown || !form.email"
                  @click="sendVerifyCode"
                >
                  {{ countdown ? `${countdown}s` : $t('huo-qu-yan-zheng-ma') }}
                </el-button>
              </div>
            </el-form-item>

            <el-form-item :label="$t('mi-ma')" prop="password" required>
              <el-input
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('qing-shu-ru-mi-ma-0')"
                v-model="form.password"
                class="form-input"
              >
                <div
                  slot="suffix"
                  class="icon-eye flex-center"
                  @click="showPassword = !showPassword"
                >
                  <img :src="eyeUrl" alt="" v-if="!showPassword" />
                  <img :src="eyeUrl2" alt="" v-else />
                </div>
              </el-input>
            </el-form-item>
            <el-form-item :label="$t('que-ren-mi-ma')" prop="password2" required>
              <el-input
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('qing-zai-ci-shu-ru-mi-ma')"
                v-model="form.password2"
                class="form-input"
              >
                <div
                  slot="suffix"
                  class="icon-eye flex-center"
                  @click="showPassword = !showPassword"
                >
                  <img :src="eyeUrl" alt="" v-if="!showPassword" />
                  <img :src="eyeUrl2" alt="" v-else />
                </div>
              </el-input>
            </el-form-item>
          </template>

          <template v-if="tabIndex == 2">
            <el-form-item :label="$t('shou-ji-hao')" prop="phone" required>
              <el-input
                class="phone-input black form-input"
                v-model="form.phone"
                :placeholder="$t('qing-shu-ru-shou-ji-hao-2')"
              >
                <template slot="prefix">
                  <div class="flex-center-start phone-pre">
                    <el-dropdown
                      placement="bottom-start"
                      @command="chooseCountry"
                      class="phone-pre-dropdown"
                    >
                      <span class="text flex-center">
                        {{ form.phonePre }}
                        <i class="el-icon-arrow-down"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <div class="phone-dropdown-item">
                          <el-input
                            v-model="keyword"
                            :placeholder="$t('sou-suo-0')"
                            @input="searchCountry"
                            @blur="searchCountry"
                          ></el-input>
                          <div
                            v-for="group in showCountryList"
                            :key="group.id"
                            ::label="group.title"
                          >
                            <el-dropdown-item
                              v-for="item in group.children"
                              :key="item.key"
                              :command="item.key"
                              >{{ item.title }}</el-dropdown-item
                            >
                          </div>
                        </div>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </template>
              </el-input>
            </el-form-item>

            <el-form-item :label="$t('yan-zheng-ma')" prop="code" required>
              <div class="verify-code-input">
                <el-input
                  v-model="form.code"
                  :placeholder="$t('qing-shu-ru-yan-zheng-ma')"
                ></el-input>
                <el-button
                  :disabled="!!countdown || !form.phone"
                  @click="sendSmsVerifyCode"
                >
                  {{ countdown ? `${countdown}s` : $t('huo-qu-yan-zheng-ma') }}
                </el-button>
              </div>
            </el-form-item>

            <el-form-item :label="$t('mi-ma')" prop="password" required>
              <el-input
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('qing-shu-ru-mi-ma-0')"
                v-model="form.password"
                class="form-input"
              >
                <div
                  slot="suffix"
                  class="icon-eye flex-center"
                  @click="showPassword = !showPassword"
                >
                  <img :src="eyeUrl" alt="" v-if="!showPassword" />
                  <img :src="eyeUrl2" alt="" v-else />
                </div>
              </el-input>
            </el-form-item>

            <el-form-item :label="$t('que-ren-mi-ma')" prop="password2" required>
              <el-input
                :type="showPassword ? 'text' : 'password'"
                :placeholder="$t('qing-zai-ci-shu-ru-mi-ma')"
                v-model="form.password2"
                class="form-input"
              >
                <div
                  slot="suffix"
                  class="icon-eye flex-center"
                  @click="showPassword = !showPassword"
                >
                  <img :src="eyeUrl" alt="" v-if="!showPassword" />
                  <img :src="eyeUrl2" alt="" v-else />
                </div>
              </el-input>
            </el-form-item>
          </template>
        </el-form>
      </div>
      <div class="flex-center-between">
        <div>
          {{ $t('yi-you-zhang-hao') }}
          <span class="link" @click="toPath('userLogin')">{{
            $t('deng-lu-0')
          }}</span>
        </div>
      </div>

      <el-button class="login-btn" @click="submit">{{
        $t('li-ji-zhu-ce')
      }}</el-button>
    </div>
  </div>
</template>
<script>
import { phoneRegister, emailRegister, sendRegisterEmailCode, verifyRegisterEmailCode, sendRegisterSmsCode, verifyRegisterSmsCode } from '@/api/login'
export default {
  data() {
    return {
      bannerUrl: require('@/assets/imgs/login-banner.png'),
      eyeUrl: require('@/assets/imgs/icon-eye-black.png'),
      eyeUrl2: require('@/assets/imgs/icon-eye2-black.png'),
      tabIndex: 1,
      form: {
        username: '',
        phone: '',
        email: '',
        phonePre: '+86',
        password: '',
        password2: '',
        code: ''
      },
      showPassword: false,
      keyword: '',
      showCountryList: [],
      countdown: 0,
      formRules: {
        phone: [
          { required: true, message: this.$t('qing-shu-ru-shou-ji-hao') },
          { pattern: /^\d{6,15}$/, message: this.$t('shou-ji-hao-ge-shi-bu-zheng-que') }
        ],
        email: [
          { required: true, message: this.$t('qing-shu-ru-you-xiang-0') },
          { type: 'email', message: this.$t('you-xiang-ge-shi-bu-zheng-que') }
        ],
        code: [
          { required: true, message: this.$t('qing-shu-ru-yan-zheng-ma') }
        ],
        password: [{ required: true, message: this.$t('qing-shu-ru-mi-ma') }],
        password2: [
          {
            required: true,
            validator: this.checkPassword
          }
        ]
      }
    }
  },
  computed: {
    langName() {
      return this.$store.state.langName
    },
    langIcon() {
      return this.$store.state.langIcon
    },
    langList() {
      return this.$store.state.langList
    },
    lang() {
      return this.$store.state.lang
    },
    countryList() {
      return this.$store.state.countryList
    }
  },
  mounted() {
    this.showCountryList = [].concat(this.countryList)
  },
  methods: {
    changeTab(i) {
      this.tabIndex = i
    },
    searchCountry() {
      if (!this.keyword) {
        this.showCountryList = [].concat(this.countryList)
      } else {
        let allList = JSON.parse(JSON.stringify(this.countryList))
        this.showCountryList = allList.map((v) => {
          v.children = v.children.filter((val) => {
            return (
              val.key.indexOf(this.keyword) > -1 ||
              val.title.toLowerCase().indexOf(this.keyword.toLowerCase()) > -1
            )
          })
          return v
        })
      }
    },
    chooseCountry(data) {
      this.form.phonePre = data
    },
    toPath(path) {
      this.$router.push({
        name: path
      })
    },
    checkPassword(rule, value, callback) {
      if (!value) {
        return callback(this.$t('qing-que-ren-mi-ma'))
      } else if (this.form.password != this.form.password2) {
        callback(this.$t('liang-ci-shu-ru-de-mi-ma-bu-yi-zhi'))
      } else {
        callback()
      }
    },
    async sendVerifyCode() {
      try {
        // 验证邮箱
        await new Promise((resolve, reject) => {
          this.$refs.form.validateField('email', (errorMessage) => {
            if (errorMessage) {
              reject(new Error(errorMessage))
            } else {
              resolve()
            }
          })
        })

        const res = await sendRegisterEmailCode({
          Email: this.form.email
        })
        
        if (res.code === 0) {
          this.$message.success(this.$t('yan-zheng-ma-yi-fa-song'))
          this.countdown = 60
          const timer = setInterval(() => {
            this.countdown--
            if (this.countdown <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          // 显示具体的错误信息
          this.$message.warning(res.msg || this.$t('fa-song-shi-bai'))
          // 如果是频率限制错误，重置倒计时
          if (res.msg.includes('请等待')) {
            this.countdown = 60
          }
        }
      } catch (error) {
        console.error(error)
        if (error.message) {
          this.$message.warning(error.message)
        } else {
          this.$message.error(this.$t('fa-song-shi-bai'))
        }
      }
    },
    async sendSmsVerifyCode() {
      try {
        // 验证手机号
        await new Promise((resolve, reject) => {
          this.$refs.form.validateField('phone', (errorMessage) => {
            if (errorMessage) {
              reject(new Error(errorMessage))
            } else {
              resolve()
            }
          })
        })

        const res = await sendRegisterSmsCode({
          Phone: this.form.phone,
          CountryCode: this.form.phonePre
        })
        
        if (res.code === 0) {
          this.$message.success(this.$t('yan-zheng-ma-yi-fa-song'))
          this.countdown = 60
          const timer = setInterval(() => {
            this.countdown--
            if (this.countdown <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          this.$message.warning(res.msg || this.$t('fa-song-shi-bai'))
          if (res.msg.includes('请等待')) {
            this.countdown = 60
          }
        }
      } catch (error) {
        console.error(error)
        if (error.message) {
          this.$message.warning(error.message)
        } else {
          this.$message.error(this.$t('fa-song-shi-bai'))
        }
      }
    },
    async submit() {
      try {
        await this.$refs.form.validate()

        if (this.tabIndex === 1) {
          const verifyRes = await verifyRegisterEmailCode({
            Email: this.form.email,
            Code: this.form.code
          })

          if (verifyRes.code !== 0) {
            this.$message.warning(verifyRes.msg)
            return
          }

          const res = await emailRegister({
            Email: this.form.email,
            Password: this.form.password,
            PasswordConfirm: this.form.password2
          })

          if (res.code === 0) {
            this.$message.success(this.$t('zhu-ce-cheng-gong'))
            this.toPath('userLogin')
          } else {
            this.$message.warning(res.msg)
          }
        } else {
          // 先验证短信验证码
          const verifyRes = await verifyRegisterSmsCode({
            Phone: this.form.phone,
            CountryCode: this.form.phonePre,
            Code: this.form.code
          })

          if (verifyRes.code !== 0) {
            this.$message.warning(verifyRes.msg)
            return
          }

          // 提交注册
          const res = await phoneRegister({
            Phone: this.form.phone,
            CountryCode: this.form.phonePre,
            Password: this.form.password,
            PasswordConfirm: this.form.password2,
            Code: this.form.code
          })

          if (res.code === 0) {
            this.$message.success(this.$t('zhu-ce-cheng-gong'))
            this.toPath('userLogin')
          } else {
            this.$message.warning(res.msg)
          }
        }
      } catch (error) {
        console.error(error)
        this.$message.error(this.$t('zhu-ce-shi-bai'))
      }
    }
  }
}
</script>

<style scoped>
.verify-code-input {
  display: flex;
  gap: 10px;
  .el-input {
    flex: 1;
  }
  .el-button {
    width: 120px;
  }
}
</style>